<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Order Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Shop Name</label
                    >

                    <v-autocomplete
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="searchShop.value"
                      :loading="searchShop.isLoading"
                      :items="searchedShopList"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="searchShop.sync"
                      flat
                      cache-items
                      label="Start typing to search a shop.."
                      hint="Start typing to search a shop..."
                      :hide-no-data="true"
                      solo
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.searchShop.value.$errors)
                      "
                      @blur="v$.searchShop.value.$touch"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Order Status</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Order Status"
                      :items="orderStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="orderStatus"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.orderStatus.$errors)
                      "
                      @blur="v$.orderStatus.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Payment Type</label
                    >

                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Choose Payment Type"
                      :items="orderPaymentTypes"
                      item-text="value"
                      item-value="key"
                      v-model="orderPaymentType"
                      :error-messages="
                        getFieldValidationErrors(v$.orderPaymentType.$errors)
                      "
                      @blur="v$.orderPaymentType.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <v-checkbox
                      v-model="isCreditOrder"
                      label="Is Credit Order"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Delivery Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Address</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Delivery Address"
                      :items="shopAddresses"
                      item-text="address_line_1"
                      item-value="id"
                      v-model="shopAddressId"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.shopAddressId.$errors)
                      "
                      @blur="v$.shopAddressId.$touch"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Date</label
                    >

                    <v-menu
                      ref="deliveryDateMenu"
                      v-model="deliveryDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="deliveryDate"
                          placeholder="Select delivery date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(v$.deliveryDate.$errors)
                          "
                          @input="v$.deliveryDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deliveryDate"
                        @input="deliveryDateMenu = false"
                        :min="getMinDeliveryDate()"
                        :max="getMaxDeliveryDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Delivery Status</label
                    >
                    <v-select
                      disabled
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Delivery Status"
                      :items="deliveryStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="deliveryStatus"
                      :error-messages="
                        getFieldValidationErrors(v$.deliveryStatus.$errors)
                      "
                      @input="v$.deliveryStatus.$touch"
                    >
                    </v-select>
                  </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Products</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <v-autocomplete
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="searchProduct.value"
                      :loading="searchProduct.isLoading"
                      :items="searchedProductList"
                      item-text="friendlyName"
                      item-value="id"
                      :search-input.sync="searchProduct.sync"
                      flat
                      cache-items
                      label="Start typing to search a product.."
                      hint="Start typing to search a product..."
                      :hide-no-data="true"
                      solo
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mt-10 mb-2">
                  <v-col v-if="orderProducts.length == 0">
                    <p>This order has no products.</p>
                  </v-col>
                  <template v-else>
                    <v-col cols="12 mb-3" md="12" class="py-0">
                      <strong>Order Total: R {{ this.getOrderTotal }}</strong>
                    </v-col>
                    <v-col
                      v-for="(product, index) in orderProducts"
                      :key="index"
                      cols="12"
                      md="3"
                      class="py-0"
                    >
                      <v-card class="card-shadow mb-6">
                        <v-img
                          height="200"
                          :src="getProductPrimaryImageUri(product)"
                        >
                        </v-img>
                        <v-card-text class="card-padding">
                          <h2 class="text-h2 text-typo font-weight-600 mb-0">
                            <v-avatar color="#52c3c3" size="30">
                              <small>{{ product.quantity || 0 }}</small>
                            </v-avatar>
                            {{ product.name }} {{ product.weight }} x {{ product.package_units }}
                          </h2>
                          <p></p>
                          <strong>
                            Unit Price: R {{ product.retail_price }} <br />
                            Total: R
                            {{ product.retail_price * product.quantity }}
                          </strong>
                          <div class="mt-5" style="text-align: center">
                            <v-icon @click="decreaseQuantity(product)" large>
                              ni ni-fat-delete
                            </v-icon>
                            <v-icon @click="increaseQuantity(product)" large>
                              ni ni-fat-add
                            </v-icon>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Submit</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import debounce from "debounce";
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "orders-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      orderStatus: { required },
      deliveryDate: { required },
      deliveryStatus: { required },
      orderPaymentType: { required },
      orderProducts: { required },
      searchShop: {
        value: { required },
      },
      shopAddressId: { required },
    };
  },
  props: {},
  data() {
    return {
      defaultImage: require("@/assets/img/placeholder-200x200.png"),
      allSearchedProducts: [],
      allSearchedShops: [],
      deliveryAddress: "",
      deliveryDate: "",
      deliveryDateMenu: null,
      deliveryStatus: "draft",
      orderDetails: {},
      orderPaymentType: "",
      orderProducts: [],
      orderStatus: "draft",
      searchProduct: {
        value: "",
        isLoading: false,
        selected: null,
        sync: null,
      },
      searchShop: {
        value: "",
        isLoading: false,
        selected: null,
        sync: null,
      },
      searchedProductList: [],
      searchedShopList: [],
      shopAddressId: "",
      shops: [],
      shopAddresses: [],
      submitButton: {
        isLoading: false,
      },
      isCreditOrder: false,
    };
  },
  computed: {
    ...mapStores(useSessionStore),

    getOrderTotal() {
      let total = 0;
      for (let i = 0; i < this.orderProducts.length; i += 1) {
        total +=
          this.orderProducts[i].retail_price * this.orderProducts[i].quantity;
      }

      return total;
    },
  },
  methods: {
    decreaseQuantity(product) {
      if (product.quantity == 1) {
        for (var i = this.orderProducts.length - 1; i >= 0; --i) {
          if (this.orderProducts[i].id == product.id) {
            this.orderProducts.splice(i, 1);
            break;
          }
        }
      } else {
        product.quantity--;
      }
    },

    increaseQuantity(product) {
      product.quantity++;
    },

    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    getProductPrimaryImageUri(product) {
      let primaryImageUri = this.defaultImage;

      if (product.images) {
        const primaryImage = product.images.find(
          (e) => e.designation == "primary"
        );

        if (primaryImage) {
          primaryImageUri = `${this.sessionStore.getApiHost}${primaryImage.image_uri}`;
        }
      }

      return primaryImageUri;
    },

    searchShopApi: debounce(function (val) {
      const searchBody = [
        {
          field: "name",
          operator: "like",
          value: val,
        },
      ];

      this.searchedProductList.splice(0);
      this.$http
        .post("shops/search", searchBody, this.sessionStore.getHttpConfig)
        .then((result) => {
          for (let i = 0; i < result.data.data.length; i += 1) {
            const tmpObj = result.data.data[i];
            this.searchedShopList.push(tmpObj);
            this.allSearchedShops.push(tmpObj);
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while searching products. Please try again later or contact support."
          );
        });
    }, 500),

    searchProductApi: debounce(function (val) {
      const searchBody = [
        {
          field: "name",
          operator: "=",
          value: val,
        },
      ];

      this.searchedProductList.splice(0);
      this.$http
        .post("products/search", searchBody, this.sessionStore.getHttpConfig)
        .then((result) => {
          for (let i = 0; i < result.data.data.length; i += 1) {
            const tmpObj = result.data.data[i];
            tmpObj.quantity = 1;
            tmpObj.friendlyName = `${tmpObj.name} ${tmpObj.weight} x ${tmpObj.package_units}`;
            this.searchedProductList.push(tmpObj);
            this.allSearchedProducts.push(tmpObj);
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while searching products. Please try again later or contact support."
          );
        });
    }, 500),

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const orderBody = {
        shop_id: this.searchShop.value,
        address_detail_id: this.shopAddressId,
        delivery_date: this.deliveryDate,
        shipment_status: this.deliveryStatus,
        products: this.orderProducts,
        status: this.orderStatus,
        payment_type: this.orderPaymentType,
      };

      if (this.isCreditOrder) {
        orderBody.tags = "credit";
      }

      this.$http
        .post("/orders", orderBody, this.sessionStore.getHttpConfig)
        .then(async (response) => {
          this.$notify.success("Order created successfully!");
          this.$router.push(`/orders/edit/${response.data.data[0].id}`);
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while creating the order. Please try again later or contact support."
          );
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.deliveryDate = this.getMinDeliveryDate().split("T")[0];

    this.$http
      .get("/shops", this.sessionStore.getHttpConfig)
      .then((result) => {
        for (let i = 0; i < result.data.data.length; i += 1) {
          this.shops.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while fetching shops. Please try again later or contact support."
        );
      });
  },
  watch: {
    "searchProduct.sync"(val) {
      val && val !== this.selected && this.searchProductApi(val);
    },
    "searchProduct.value"(val) {
      if (val) {
        const product = this.allSearchedProducts.find((e) => e.id == val);
        if (product) {
          const productAlreadyAdded = this.orderProducts.find(
            (e) => e.id == val
          );
          if (!productAlreadyAdded) {
            this.orderProducts.push(product);
          }
        }
      }
    },
    "searchShop.sync"(val) {
      val && val !== this.selected && this.searchShopApi(val);
    },
    "searchShop.value"(val) {
      if (val) {
        const shop = this.allSearchedShops.find((e) => e.id == val);
        if (shop) {
          const shopAlreadyAdded = this.shops.find((e) => e.id == val);
          if (!shopAlreadyAdded) {
            this.shops.push(shop);
          }

          this.shopAddresses.splice(0);
          if (shop.address_details.length > 0) {
            for (let i = 0; i < shop.address_details.length; i += 1) {
              this.shopAddresses.push(shop.address_details[i]);
            }
          } else {
            this.$notify.warning(
              "The selected shop does not have address details. Please update the shop first."
            );
          }
        }
      }
    },
  },
};
</script>
